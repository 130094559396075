<template>
  <div class="bookmaster-wraper">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <form class="form-horizontal" v-on:submit.prevent="passes(createOrUpdate)">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <template>
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.mansion") }}</label>
                  <div class="col-xl-5 col-lg-8 col-sm-8 p-t-8">{{ entry.apartment_id ? getApartmentName(entry.apartment_id) : meta.apartmentName }}</div>
                </div>
              </template>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("transport.car_name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="名前（車名）"
                    type="string128"
                    rules="required"
                    v-model="entry.name"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("book-masters.time") }} <span class="app-require">＊</span></label>
                <div class="col-xl-2 col-lg-3 col-sm-3">
                  <app-select
                    name="opening_time"
                    :rules="`required|opening_time:${entry.closing_time}`"
                    :options-data="meta.opening_time"
                    v-model="entry.opening_time"
                  />
                </div>
                <div class="col-xl-1 col-lg-2 col-sm-2 text-center">
                  <span class="text-label">～</span>
                </div>
                <div class="col-xl-2 col-lg-3 col-sm-3">
                  <app-select
                    name="closing_time"
                    rules="required"
                    :options-data="meta.closing_time"
                    v-model="entry.closing_time"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("book-masters.unit_time") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-select
                    name="unit_time"
                    rules="required"
                    :options-data="meta.unit_time"
                    v-model="entry.unit_time"
                  />
                </div>
                <div class="col-xl-1 col-lg-2 col-sm-2">
                  <span class="text-label text-bold">分</span>
                </div>
              </div>
              <validation-provider
                name="work_days"
                rules="required"
                v-slot="{ errors, invalid, validated }"
                ref="work_days"
              >
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("book-masters.work_days") }} <span class="app-require">＊</span></label>
                  <div class="col-xl-6 col-lg-8 col-sm-8 gr-icheck">
                    <app-checkbox
                      :label="$t('book-masters.sunday')"
                      v-model="entry.work_days"
                      class="m-r-20"
                      val="sun"
                    />
                    <app-checkbox
                      :label="$t('book-masters.monday')"
                      v-model="entry.work_days"
                      class="m-r-20"
                      val="mon"
                    />
                    <app-checkbox
                      :label="$t('book-masters.tuesday')"
                      v-model="entry.work_days"
                      class="m-r-20"
                      val="tue"
                    />
                    <app-checkbox
                      :label="$t('book-masters.wednesday')"
                      v-model="entry.work_days"
                      class="m-r-20"
                      val="wed"
                    />
                    <app-checkbox
                      :label="$t('book-masters.thursday')"
                      v-model="entry.work_days"
                      class="m-r-20"
                      val="thu"
                    />
                    <app-checkbox
                      :label="$t('book-masters.friday')"
                      v-model="entry.work_days"
                      class="m-r-20"
                      val="fri"
                    />
                    <app-checkbox
                      :label="$t('book-masters.saturday')"
                      v-model="entry.work_days"
                      class="m-r-20"
                      val="sat"
                    />
                    <label v-if="invalid && validated" class="error">
                      {{ errors[0] }}
                    </label>
                  </div>
                </div>
              </validation-provider>
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-xl-1-4 col-form-label">{{ $t("transport.unit_capacity") }} <span class="app-require">＊</span></label>
                <div class="col-xl-1 col-lg-2 col-sm-2">
                  <app-input
                    name="乗車定員"
                    type="number-length:4"
                    rules="required|min_value:1"
                    v-model="entry.unit_capacity"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("book-masters.limit_booking_hours") }}</label>
                <div class="col-xl-1 col-lg-2 col-sm-2 error-text-align-justify">
                  <app-input
                    name="limit_booking_hours"
                    type="number-length:3"
                    rules="max_value:255|min_limit_booking_hour:1"
                    v-model="entry.limit_booking_hours"
                  />
                </div>
                <label class="col-xl-1 col-lg-1 col-sm-2 title-rh col-form-label">{{ $t("book-masters.limit_booking_times") }} </label>
                <div class="col-xl-1 col-lg-2 col-sm-2 error-text-align-justify">
                  <app-input
                    name="limit_booking_times"
                    type="number-length:3"
                    rules="max_value:255|min_limit_booking_times:1"
                    v-model="entry.limit_booking_times"
                  />
                </div>
                <div class="col-xl-1 col-lg-2 col-sm-2">
                  <span class="text-label text-bold">回</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("transport.interval_unit_count") }}</label>
                <div class="col-xl-1 col-lg-2 col-sm-2 error-text-align-justify">
                  <app-input
                    name="interval_unit_count"
                    type="number-length:3"
                    rules="min_value:0"
                    v-model="entry.interval_unit_count"
                  />
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-4">
                  <span class="text-label text-bold">コマ（予約の時間単位）</span>
                </div>
              </div>

              <div class="card-footer row justify-content-end">
                <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                  {{ $t('common.cancel') }}
                </button>
                <button v-if="idEditing" :disabled="!valid && validated" @click="isFirstLoad++" class="btn btn-primary m-r-10" type="submit">
                  {{  $t('common.save') }}
                </button>
                <button v-else :disabled="!valid && validated" @click="isFirstLoad++" class="btn btn-primary m-r-10" type="submit">
                  {{  $t('common.create') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import AppCheckbox from "../../../components/forms/AppCheckbox";
import {__getLocalStorage, __redirectBack, __removeLocalStorage} from "@utils";
export default {
  name: "TransportCreateOrEdit",

  components: {
    AppCheckbox,
  },

  data() {
    const id = this.$route.params.id
    const apartmentName = this.$route.params.apartmentName
    const apartmentId = this.$route.params.apartment_id
    return {
      checkbox: {
        block: ["opton 1", "option 2"],
        inline: [],
        circle: [],
        right: [],
      },
      entry: {
        work_days: [],
      },
      meta: {
        apartments: [],
        opening_time: [],
        closing_time: [],
      },
      idEditing: id,
      apartment_id: apartmentId,
      apartmentName: apartmentName,
      isFirstLoad: 0,
    };
  },

  mounted: async function () {
    $(".tooltip").tooltip("hide");
    let responseTransportCar = await this.$request.get(this.ENDPOINT.UI_HELPER_TRANSPORT_CAR_MASTERS);
    if(!responseTransportCar.hasErrors()) {
      let apartmentName = this.__getLocalStorage('apartmentName')
      let idApartment = this.__getLocalStorage('apartment_id')
      let checkApartmentisExists = await this.$request.get(this.ENDPOINT.UI_HELPER_APARTMENTS(idApartment))
      if (checkApartmentisExists.data.apartment.length == 0) {
        await this.redirectDefaultValue();
      }
      let apartments = responseTransportCar.data.apartments;
      apartments.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      let  opening_time = responseTransportCar.data.opening_time;
      opening_time.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      let  closing_time = responseTransportCar.data.closing_time;
      closing_time.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      let  unit_time = responseTransportCar.data.unit_time;
      unit_time.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      this.meta = {
        apartments: apartments,
        apartmentName: apartmentName,
        opening_time: opening_time,
        closing_time: closing_time,
        unit_time: unit_time,
        idApartment: idApartment
      }
    }
    if (this.idEditing) {
      let response = await this.$request.get(this.ENDPOINT.TRANSPORTS_EDIT(this.idEditing));
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
      } else {
        this.entry = _.cloneDeep(response.data.data);
      }
    }
  },
  watch: {
    "entry.work_days.length" : function (value, old) {
      if (this.isFirstLoad) {
        this.$refs.work_days.validate()
      }
      this.isFirstLoad++
    },
  },
  methods: {
    confirmCancel() {
      if (this.__getLocalStorage('is_from_apartment_page')) {
        this.$router.push({
          name: this.ROUTES.ADMIN.APARTMENT_EDIT,
          params: {id: this.apartment_id ? this.apartment_id : this.meta.idApartment, actionFormTransportCar: true}
        }).catch(e => {
          console.log('no warning console');
        });
        this.__removeLocalStorage('is_from_apartment_page');
      } else {
        this.redirectBeforeTab();
      }
    },
    async createOrUpdate() {
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        params.apartment_id = this.apartment_id ? this.apartment_id : this.meta.idApartment
        params.apartmentName = this.apartmentName ? this.apartmentName : this.meta.apartmentName;
        params.name = $.trim(params.name);
        res = await this.$request.patch(this.ENDPOINT.TRANSPORTS_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        params.apartment_id = this.apartment_id ? this.apartment_id : this.meta.idApartment
        params.apartmentName = this.apartmentName ? this.apartmentName : this.meta.apartmentName;
        res = await this.$request.post(this.ENDPOINT.TRANSPORTS_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        await this.redirectBeforeTab();
      }
    },
    async redirectBeforeTab() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.APARTMENT_EDIT,
        params: {id: this.apartment_id ? this.apartment_id : this.meta.idApartment, actionFormTransportCar: true}
      }).catch(e => {
        console.log('no warning console');
      });
    },
    getApartmentName(apartmentId) {
      let apartment = this.meta.apartments.find(({id}) => id === apartmentId);
      if (apartment == undefined) {
        return '';
      }
      return apartment.name;
    },
    async redirectDefaultValue() {
      await this.$router.push({name: this.ROUTES.ADMIN.APARTMENT_LIST, query: {'filters.pref_id.equal': 'all'}});
    },
  },
};
</script>
